// REACT
import React from "react"
// MUI
import { Grid, Typography } from "@material-ui/core"
import MuiLink from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageWithCaption } from "../components/imageWithCaption"
import { Link } from "../components/link"
import LogoEvinrudeBrp from "../assets/logo-evinrude-brp.svg"
import LogoJohnsonOutboards from "../assets/logo-johnson-outboards.svg"
import LogoHondaMarine from "../assets/logo-honda-marine.svg"

// STYLES
const useStyles = makeStyles(theme => ({
  logo: {
    width: "100%",
    height: "auto",
  },
}))

// COMPONENT FUNCTION
const PartsPage = () => {
  // STYLE OBJECT
  const classes = useStyles()

  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      partsDepartment: file(relativePath: { eq: "parts-department.jpg" }) {
        ...BsmFluidImage
      }
      brpPartsCatalog: file(relativePath: { eq: "brp-parts-catalog.jpg" }) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Parts">
      <PageTitle title="PARTS" />
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography paragraph>
            Bay Sails has a replacement part for almost everything in boating.
            We carry Parker fittings and hydraulics, turnbuckles, trailer
            lights, Sunfish parts, and much more. When you can’t find a part
            anywhere else we usually have it. When you come in for a replacement
            part please be able to give us as much information as possible about
            where the part came from so that we can find you the correct
            replacement quickly.
          </Typography>
          <Typography paragraph>
            <strong>
              After 30 years in the business you collect a lot of stuff. We may
              have exactly what you are looking for to complete your re-build
              project. We are happy to ship parts. Just{" "}
              <Link to="/contact">give us a call</Link> with a part number or
              really good description.
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ImageWithCaption
            fluid={data.partsDepartment.childImageSharp.fluid}
            caption="Bay Sails Marine parts drawers"
            displayCaption
            maxWidth={400}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2rem" }}>
          <Typography paragraph>
            We carry parts for Honda, Johnson and Evinrude motors. When you come
            in for a part please know the <strong>YEAR</strong> and{" "}
            <strong>MODEL NUMBER</strong> of your motor so that we can find the
            correct part and get you back on the water fast.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <LogoEvinrudeBrp className={classes.logo} />
        </Grid>
        <Grid item xs={4}>
          <LogoJohnsonOutboards className={classes.logo} />
        </Grid>
        <Grid item xs={4}>
          <LogoHondaMarine className={classes.logo} />
        </Grid>
        <Grid item xs={12} sm={8} style={{ marginTop: "2rem" }}>
          <Typography paragraph>
            The{" "}
            <MuiLink href="http://epc.brp.com/default.aspx?brands=ej&lang=E&dealerlocator=no">
              Bombardier Electronic Parts Catalog
            </MuiLink>
            . This will let you see diagrams of your Evinrude or Johnson motor.
            You’ll be able to find the exact part number you need and print a
            pick ticket that you can bring to us.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ImageWithCaption
            fluid={data.brpPartsCatalog.childImageSharp.fluid}
            caption="BRP Parts Catalog"
            maxWidth={200}
          />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default PartsPage
